<template>
  <b-card header-tag="header" footer-tag="footer">
    <template v-slot:header>
      <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
        <h6 class="mb-0">Device Location</h6>
        <div style="display: flex; align-items: center; gap: 15px;">
          <div class="form-group mb-0">
            <label class="small mb-1">Start</label>
            <flat-pickr
              class="form-control"
              :config="dateTimeConfig"
              v-model="form.dateTimeStart"
              @on-change="onStartChange"
              placeholder="Select start date"
            />
          </div>
          <div class="form-group mb-0">
            <label class="small mb-1">End</label>
            <flat-pickr
              class="form-control"
              :config="dateTimeConfig"
              v-model="form.dateTimeEnd"
              @on-change="onEndChange"
              placeholder="Select end date"
            />
          </div>
          <b-button variant="success" @click="downloadData" :disabled="btnUpdate || !mapsApiLoaded">
            <b-spinner small v-show="btnUpdate"></b-spinner>
            Update
          </b-button>
        </div>
      </div>
    </template>

    <template v-if="statusLoaded == null">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner variant="primary"></b-spinner>
      </div>
    </template>
    <template v-else>
      <GmapMap
        :center="center"
        @click="addPoint"
        :zoom="12"
        ref="map2"
        style="width: 100%; height: 400px;"
        @tilesloaded="fitMarkers"
      >
        <GmapPolygon
          v-for="(geofence, index) in geofences"
          :key="index"
          :path="geofence.points"
          :options="{
            fillColor: geofence.color,
            fillOpacity: 0.5,
            strokeColor: geofence.color,
            strokeOpacity: 1,
            strokeWeight: 2,
          }"
          @click="selectGeofence(geofence)"
        />
        <GmapMarker
          :position="center"
          :zoom="1"
          :icon="{ url: require('../../../../../public/media/markers/man2.png') }"
        />
        <GmapMarker
          v-for="(m, index) in displayedMarkers"
          :key="index"
          :position="m.location"
          :clickable="true"
          :draggable="true"
          :icon="{ url: require('../../../../../public/media/markers/marker.png') }"
          @click="m.showTooltip = true"
        >
          <GmapInfoWindow :opened="m.showTooltip" @closeclick="m.showTooltip = false">
            <div>{{ formatDate(m.created_at) }}</div>
          </GmapInfoWindow>
        </GmapMarker>
      </GmapMap>
      <div class="mt-3">
        <b-button-toolbar>
          <b-button-group class="mr-2">
            <b-button variant="primary" @click="addNewGeofence" :disabled="!mapsApiLoaded">
              <i class="bi bi-plus-circle mr-1"></i> Add Geofence
            </b-button>
            <b-button variant="danger" @click="clearAllGeofences">
              <i class="bi bi-trash mr-1"></i> Clear All
            </b-button>
            <b-button variant="warning" @click="deleteSelectedGeofence" :disabled="!selectedGeofence">
              <i class="bi bi-x-circle mr-1"></i> Delete Selected
            </b-button>
          </b-button-group>
          <b-button
            v-if="activeGeofence"
            variant="outline-secondary"
            @click="stopEditingGeofence"
            class="ml-2"
          >
            <i class="bi bi-stop-circle mr-1"></i> Stop Editing
          </b-button>
          <b-button
            v-if="activeGeofence"
            variant="outline-success"
            @click="saveGeofence"
            class="ml-2"
            :disabled="activeGeofence.points.length < 3"
          >
            <i class="bi bi-save mr-1"></i> Save Geofence
          </b-button>
        </b-button-toolbar>
      </div>
      <b-table class="mt-3" :items="tableItems" :fields="polygonsTableFields">
        <template #cell(color)="data">
          <div class="color-square" :style="{ backgroundColor: data.value }"></div>
        </template>
        <template #cell(notify_enter)="data">
          <b-form-checkbox
            v-model="data.item.notify_enter"
            @change="updateGeofence(data.item)"
            :disabled="!data.item.id"
          />
        </template>
        <template #cell(notify_exit)="data">
          <b-form-checkbox
            v-model="data.item.notify_exit"
            @change="updateGeofence(data.item)"
            :disabled="!data.item.id"
          />
        </template>
        <template #cell(actions)="data">
          <b-button
            v-if="!data.item.id"
            variant="outline-success"
            size="sm"
            @click="saveGeofenceFromTable(data.item)"
            :disabled="data.item.points.length < 3"
          >
            <i class="bi bi-save mr-1"></i> Save
          </b-button>
          <b-button
            v-if="data.item.id"
            variant="outline-danger"
            size="sm"
            @click="deleteGeofenceFromTable(data.item)"
          >
            <i class="bi bi-trash mr-1"></i> Delete
          </b-button>
        </template>
      </b-table>
      <div class="mt-3">
        <b-button v-b-toggle.collapse-1 variant="primary">Show location details</b-button>
        <b-collapse id="collapse-1" class="mt-2">
          <b-card>
            <b-table
              striped
              hover
              :items="markers"
              @row-clicked="rowClicked"
              :tbody-tr-class="rowClassCallback"
              :fields="tableFields"
            >
              <template v-slot:cell(created_at)="data">
                {{ formatDate(data.item.created_at) }}
              </template>
            </b-table>
          </b-card>
        </b-collapse>
      </div>

      <!-- Geofence Name Modal -->
      <b-modal ref="geofence-name-modal" title="Geofence Settings" hide-footer>
        <form @submit.prevent="submitGeofenceName">
          <b-form-group label="Enter a name for this geofence:">
            <b-form-input v-model="newGeofenceName" required minlength="1"></b-form-input>
          </b-form-group>
          <b-form-group label="Notifications">
            <b-form-checkbox v-model="notifyEnter" class="mb-2">
              Notify on Enter
            </b-form-checkbox>
            <b-form-checkbox v-model="notifyExit">
              Notify on Exit
            </b-form-checkbox>
          </b-form-group>
          <b-button type="submit" variant="primary" class="mt-2">Save</b-button>
          <b-button variant="secondary" class="mt-2 ml-2" @click="hideModal">Cancel</b-button>
        </form>
      </b-modal>
    </template>
  </b-card>
</template>

<script>
import { GET_FINDMY_LOCATION, POST_SAVE_GEOFENCE, GET_GEOFENCES, DELETE_GEOFENCE,UPDATE_GEOFENCE } from "@/core/services/store/devices.module";
import { gmapApi } from "vue2-google-maps";
import { mapGetters } from "vuex";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "suitch-map-widget",
  components: {
    flatPickr,
  },
  props: {
    device: null,
    showName: {
      type: Boolean,
      default: false,
    },
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  mounted() {
    this.waitForGoogleMaps()
      .then(() => {
        this.mapsApiLoaded = true;
        this.downloadData();
      })
      .catch(error => {
        console.error("Failed to load Google Maps API:", error);
        this.statusLoaded = {};
      });
  },
  data() {
    return {
      unsubscribe: null,
      activeGeofence: null,
      selectedGeofence: null,
      geofences: [],
      current_device: { connection_type: -1 },
      statusLoaded: null,
      center: { lat: 25.686613, lng: -100.316116 },
      markers: [],
      polygonsTableFields: [
        { key: "geo_name", label: "Name" },
        { key: "color", label: "Color" },
        { key: "count", label: "Marker Count" },
        { key: "notify_enter", label: "Notify Enter" },
        { key: "notify_exit", label: "Notify Exit" },
        { key: "actions", label: "Actions" },
      ],
      tableItems: [],
      newGeofenceName: "",
      notifyEnter: false,
      notifyExit: false,
      mapsApiLoaded: false,
      show: "day",
      markersLoaded: false,
      tableFields: [
        { key: "location", label: "Location" },
        { key: "created_at", label: "Updated At", formatter: "formatDate" },
      ],
      selectedMarker: null,
      btnUpdate: false,
      form: {
        dateTimeStart: new Date(Date.now() - 0.3 * 24 * 60 * 60 * 1000).toISOString().slice(0, 16),
        dateTimeEnd: new Date().toISOString().slice(0, 16),
      },
      dateTimeConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        time_24hr: true,
        defaultHour: 0,
        defaultMinute: 0,
      },
    };
  },
  methods: {
    waitForGoogleMaps() {
      return new Promise((resolve, reject) => {
        if (window.google && window.google.maps && window.google.maps.geometry) {
          resolve();
        } else {
          this.$gmapApiPromiseLazy()
            .then(() => {
              if (window.google.maps.geometry) {
                resolve();
              } else {
                reject(new Error("Geometry library not loaded"));
              }
            })
            .catch(error => reject(error));
        }
      });
    },
    addPoint(e) {
      if (this.activeGeofence !== null) {
        this.activeGeofence.points.push(e.latLng);
        this.updateTable();
      }
    },
    addNewGeofence() {
      const newGeofence = { points: [], color: this.randomColor(), geo_name: "", notify_enter: false, notify_exit: false };
      this.geofences.push(newGeofence);
      this.activeGeofence = newGeofence;
      this.updateTable();
    },
    async stopEditingGeofence() {
      if (this.activeGeofence && this.activeGeofence.points.length > 0) {
        await this.promptForGeofenceName(() => {
          this.activeGeofence = null;
          this.updateTable();
        });
      } else {
        this.activeGeofence = null;
        this.updateTable();
      }
    },
    async saveGeofence() {
      if (this.activeGeofence && this.activeGeofence.points.length > 2) {
        await this.promptForGeofenceName(() => {
          this.$store
            .dispatch(POST_SAVE_GEOFENCE, {
              token: this.device?.token,
              geo_color: this.activeGeofence.color,
              geo_shape: JSON.stringify(this.activeGeofence.points.map(p => ({ lat: p.lat(), lng: p.lng() }))),
              geo_name: this.activeGeofence.geo_name,
              notify_enter: this.activeGeofence.notify_enter,
              notify_exit: this.activeGeofence.notify_exit,
            })
            .then(() => {
              this.activeGeofence = null;
              this.downloadData();
            })
            .catch(error => {
              console.error("Error saving geofence:", error);
            });
        });
      }
    },
    async saveGeofenceFromTable(geofence) {
      this.activeGeofence = geofence;
      if (geofence.points.length >= 3) {
        await this.promptForGeofenceName(() => {
          this.$store
            .dispatch(POST_SAVE_GEOFENCE, {
              token: this.device?.token,
              geo_color: geofence.color,
              geo_shape: JSON.stringify(geofence.points.map(p => ({ lat: p.lat(), lng: p.lng() }))),
              geo_name: geofence.geo_name,
              notify_enter: geofence.notify_enter,
              notify_exit: geofence.notify_exit,
            })
            .then(() => {
              this.activeGeofence = null;
              this.downloadData();
            })
            .catch(error => {
              console.error("Error saving geofence from table:", error);
            });
        });
      }
    },
    updateGeofence(geofence) {
      if (geofence.id) {
        this.$store
          .dispatch(UPDATE_GEOFENCE, {
            token: this.device?.token,
            geofence_id: geofence.id,
            notify_enter: geofence.notify_enter,
            notify_exit: geofence.notify_exit,
          })
          .then(() => {
            this.downloadData();
          })
          .catch(error => {
            console.error("Error updating geofence:", error);
            // Optionally revert the change if the update fails
            this.downloadData(); // Refresh to ensure consistency
          });
      }
    },
    deleteGeofenceFromTable(geofence) {
      this.$store
        .dispatch(DELETE_GEOFENCE, { token: this.device?.token, geofence_id: geofence.id })
        .then(() => {
          const index = this.geofences.findIndex(g => g.id === geofence.id);
          if (index > -1) {
            this.geofences.splice(index, 1);
            if (this.activeGeofence === geofence) this.activeGeofence = null;
            if (this.selectedGeofence === geofence) this.selectedGeofence = null;
            this.updateTable();
          }
        })
        .catch(error => {
          console.error("Error deleting geofence:", error);
        });
    },
    promptForGeofenceName(callback) {
      if (this.activeGeofence.geo_name) {
        callback();
      } else {
        this.newGeofenceName = "";
        this.notifyEnter = false;
        this.notifyExit = false;
        this.$refs["geofence-name-modal"].show();
        this.$once("geofence-name-submitted", () => {
          if (this.newGeofenceName) {
            this.activeGeofence.geo_name = this.newGeofenceName;
            this.activeGeofence.notify_enter = this.notifyEnter;
            this.activeGeofence.notify_exit = this.notifyExit;
            callback();
          }
        });
      }
    },
    submitGeofenceName() {
      if (this.newGeofenceName) {
        this.$emit("geofence-name-submitted");
        this.hideModal();
      }
    },
    showModal() {
      this.$refs["geofence-name-modal"].show();
    },
    hideModal() {
      this.$refs["geofence-name-modal"].hide();
    },
    selectGeofence(geofence) {
      this.selectedGeofence = geofence;
    },
    clearAllGeofences() {
      this.geofences = [];
      this.activeGeofence = null;
      this.selectedGeofence = null;
      this.updateTable();
    },
    deleteSelectedGeofence() {
      if (this.selectedGeofence !== null) {
        if (this.selectedGeofence.id) {
          this.deleteGeofenceFromTable(this.selectedGeofence);
        } else {
          const index = this.geofences.indexOf(this.selectedGeofence);
          if (index > -1) {
            this.geofences.splice(index, 1);
            if (this.activeGeofence === this.selectedGeofence) this.activeGeofence = null;
            this.selectedGeofence = null;
            this.updateTable();
          }
        }
      }
    },
    updateTable() {
      this.tableItems = this.geofences.map(geofence => {
        const polygon = new window.google.maps.Polygon({ paths: geofence.points });
        const count = window.google.maps.geometry && window.google.maps.geometry.poly
          ? this.markers.reduce((count, marker) => {
              if (window.google.maps.geometry.poly.containsLocation(marker.location, polygon)) {
                count++;
              }
              return count;
            }, 0)
          : 0;
        return {
          id: geofence.id || null,
          geo_name: geofence.geo_name || "Unnamed",
          color: geofence.color,
          count,
          notify_enter: geofence.notify_enter || false,
          notify_exit: geofence.notify_exit || false,
          points: geofence.points,
        };
      });
      this.$forceUpdate();
    },
    randomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    rowClassCallback(item, type) {
      if (type === "row") {
        return this.isSelected(item) ? "map-selected-row" : "";
      }
      return "";
    },
    rowClicked(clickedItem) {
      if (this.selectedMarker === clickedItem) {
        this.selectedMarker = null;
      } else {
        this.selectedMarker = clickedItem;
      }
    },
    formatDate(dateString) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      try {
        const date = new Date(dateString);
        if (isNaN(date)) throw new Error("Invalid date");
        return new Intl.DateTimeFormat(undefined, options).format(date);
      } catch (err) {
        console.error("Error formatting date:", err);
        return null;
      }
    },
    fitMarkers() {
      if (this.markersLoaded || !this.mapsApiLoaded) return;
      if ((this.markers.length > 0 || this.geofences.length > 0) && window.google.maps && this.$refs.map2) {
        const bounds = new window.google.maps.LatLngBounds();
        for (let m of this.markers) {
          bounds.extend(m.location);
        }
        for (let g of this.geofences) {
          for (let p of g.points) {
            bounds.extend(p);
          }
        }
        this.$refs.map2.fitBounds(bounds);
        this.markersLoaded = true;
      }
    },
    onStartChange(selectedDates, dateStr) {
      this.form.dateTimeStart = dateStr;
      if (this.form.dateTimeEnd && new Date(this.form.dateTimeEnd) < new Date(dateStr)) {
        this.form.dateTimeEnd = dateStr;
      }
    },
    onEndChange(selectedDates, dateStr) {
      this.form.dateTimeEnd = dateStr;
    },
    downloadData() {
      if (!this.mapsApiLoaded) {
        this.waitForGoogleMaps()
          .then(() => {
            this.mapsApiLoaded = true;
            this.downloadData();
          })
          .catch(error => {
            console.error("Failed to load Google Maps API in downloadData:", error);
          });
        return;
      }

      this.btnUpdate = true;
      this.markers = [];
      this.geofences = [];
      this.markersLoaded = false;
      if (this.device) {
        const startDate = this.form.dateTimeStart ? new Date(this.form.dateTimeStart).toISOString() : null;
        const endDate = this.form.dateTimeEnd ? new Date(this.form.dateTimeEnd).toISOString() : null;

        Promise.all([
          this.$store.dispatch(GET_GEOFENCES, { device_token: this.device.token }),
          this.$store.dispatch(GET_FINDMY_LOCATION, {
            device_token: this.device.token,
            start_date: startDate,
            end_date: endDate,
          }),
        ])
          .then(([geofenceData, locationData]) => {
            this.geofences = geofenceData.map(g => {
              let points;
              try {
                const parsedShape = JSON.parse(g.geo_shape);
                points = Array.isArray(parsedShape)
                  ? parsedShape.map(p => {
                      if (!p.lat || !p.lng) {
                        return new window.google.maps.LatLng(0, 0);
                      }
                      return new window.google.maps.LatLng(p.lat, p.lng);
                    })
                  : [];
              } catch (parseError) {
                points = [];
              }
              const notify_enter_local = (g.notify_enter == 0 || g.notify_enter == null) ? false : true;
              const notify_exit_local = (g.notify_exit == 0 || g.notify_exit == null) ? false : true;
              return {
                id: g.id || null,
                points,
                color: g.geo_color || "#000000",
                geo_name: g.geo_name || "Unnamed",
                notify_enter: notify_enter_local,
                notify_exit: notify_exit_local,
              };
            });

            this.markers = locationData.map(item => {
              const decodedValue = JSON.parse(atob(item.value));
              const { lat, lon: lng } = decodedValue;
              return {
                location: new window.google.maps.LatLng(lat || 0, lng || 0),
                created_at: item.created_at,
                showTooltip: false,
              };
            });

            this.statusLoaded = {};
            this.btnUpdate = false;
            this.updateTable();

            this.$nextTick(() => {
              this.updateTable();
            });
          })
          .catch(error => {
            console.error("Error fetching data in downloadData:", error);
            this.btnUpdate = false;
            this.statusLoaded = {};
          });
      } else {
        this.btnUpdate = false;
      }
    },
    isSelected(item) {
      return this.selectedMarker === item;
    },
    getLocationPermission() {
      navigator.permissions.query({ name: "geolocation" }).then(result => {
        if (result.state === "granted" || result.state === "prompt") {
          this.geolocate();
        }
      });
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const bounds = new window.google.maps.LatLngBounds();
        if (this.markers.length > 0 || this.geofences.length > 0) {
          for (let m of this.markers) {
            bounds.extend(m.location);
          }
          for (let g of this.geofences) {
            for (let p of g.points) {
              bounds.extend(p);
            }
          }
          this.$refs.map2.fitBounds(bounds);
        }
      });
    },
  },
  computed: {
    google: gmapApi,
    ...mapGetters(["layoutConfig"]),
    displayedMarkers() {
      return this.selectedMarker ? [this.selectedMarker] : this.markers;
    },
  },
  watch: {
    markers(markers) {
      markers;
    },
    device(oldVal, newVal) {
      oldVal, newVal;
      this.downloadData();
    },
    geofences: {
      handler(newGeofences) {
        newGeofences;
        this.updateTable();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
/* Style Flatpickr */
.form-group {
  margin-right: 10px;
}
.form-control {
  width: 180px;
  height: 38px;
}
label {
  font-size: 0.9rem;
}

/* Style Buttons */
.btn-toolbar {
  justify-content: flex-start;
}
.btn-group .btn {
  padding: 6px 12px;
  font-size: 0.9rem;
}
.btn-outline-secondary,
.btn-outline-success {
  padding: 6px 12px;
  font-size: 0.9rem;
}

/* Color Square in Table */
.color-square {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: 1px solid #ccc;
  vertical-align: middle;
}
</style>